/*=================================================================*/
/*                      SECTIONS                             
/*=================================================================*/
main.content {
	margin-left: 290px;
	@include transition(.3s);

	&.open {
		opacity: .8;
		-webkit-transform: translateX(290px);
		-moz-transform: translateX(290px);
		-ms-transform: translateX(290px);
		-o-transform: translateX(290px);
		transform: translateX(290px);
	}
}

main.content-2 {
	margin-left: 110px;
	@include transition(.3s);

	&.open {
		opacity: .8;
		-webkit-transform: translateX(110px);
		-moz-transform: translateX(110px);
		-ms-transform: translateX(110px);
		-o-transform: translateX(110px);
		transform: translateX(110px);
	}
}



section {
	padding-top: 110px;
	position: relative;
}

section.home {
	background: $colordark;
	padding: 0;
	height: 100vh;
	min-height: 100vh;

	.intro {
		margin: auto;
		max-width: 540px;
		text-align: center;
		position: relative;
		z-index: 1;

		h1 {
			color: #FFF;
			font-size: 36px;
		}

		p {
			color: #FFF;
			font-size: 18px;
			margin-bottom: 0;
		}

		span {
			display: inline-block;

			label {
				margin-bottom: 0;

				&::after {
					content: attr(value);
				}
			}
		}
	}

	.social-icons {
		li {
			a {
				color: #FFF;
			}
		}
	}

	
}

.section-title {
	font-size: 36px;
	margin: 0;
	margin-left: 14px;
	position: relative;

	&:before {
		content: '';
		display: block;
		height: 37px;
		left: -14px;
		top: -14px;
		position: absolute;
		width: 37px;
	}
}

/*=================================================================*/
/*                      PARALLAX SHAPES                              
/*=================================================================*/
.parallax {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;

	.layer {
		position: absolute;
	}

	.p1 {
		left: 10%;
		top: 10%;
	}

	.p2 {
		left: 25%;
		top: 30%;
	}

	.p3 {
		left: 15%;
		bottom: 30%;
	}

	.p4 {
		left: 10%;
		bottom: 10%;
	}

	.p5 {
		left: 45%;
		top: 10%;
	}

	.p6 {
		left: 40%;
		bottom: 10%;
	}

	.p7 {
		top: 20%;
		right: 30%;
	}

	.p8 {
		right: 30%;
		bottom: 20%;
	}

	.p9 {
		right: 10%;
		top: 10%;
	}

	.p10 {
		top: 45%;
		right: 20%;
	}

	.p11 {
		bottom: 10%;
		right: 10%;
	}
}

/*=================================================================*/
/*                      SKILLS                              
/*=================================================================*/
.skill-item {
	.skill-info {
		h4 {
			font-size: 16px;
			font-weight: 500;
		}

		span {
			font-size: 14px;
		}
	}
}

/*=================================================================*/
/*                      SERVICE                              
/*=================================================================*/
.service-box {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	@include transition(.3s);

	h3 {
		font-size: 20px;
	}

	img {
		margin-bottom: 22px;
	}

	&:hover {
		-webkit-transform: translateY(-10px);
		-ms-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}

/*=================================================================*/
/*                      PORTFOLIO                              
/*=================================================================*/
.portfolio-wrapper {
	[class*="col-"] {
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.pf-filter-wrapper {
	display: none;
}

.portfolio-item {
	position: relative;
	overflow: hidden;

	.thumb {
		overflow: hidden;
	}

	.details {
		color: #FFF;
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 1;

		h4 {
			color: #FFF;
			font-size: 20px;
			margin: 0 0 10px;
			padding: 0 20px;
			opacity: 0;
			transform: translateY(30px);
			transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
			position: absolute;
			top: 60px;
		}

		span.term {
			color: #FFF;
			background: $colorpink;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			font-size: 14px;
			opacity: 0;
			display: inline-block;
			padding: 3px 10px;
			position: absolute;
			top: 0;
			left: 20px;
			transform: translateY(-40px);
			transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		}

		.more-button {
			color: #FFF;
			font-size: 20px;
			display: block;
			background: $coloraqua;
			border-radius: 100%;
			height: 40px;
			line-height: 42px;
			text-align: center;
			position: absolute;
			bottom: 20px;
			left: 20px;
			width: 40px;
			opacity: 0;
			@include transition(.3s);
		}
	}

	.mask {
		background: $colorgray;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		height: 100%;
		width: 100%;
		@include transition(.3s);
	}

	&:hover {
		.mask {
			opacity: .9;
		}

		.details {

			h4,
			span {
				opacity: 1;
				transform: translateY(0);
			}

			.more-button {
				opacity: 1;
			}
		}
	}
}

.portfolio-filter {
	margin-bottom: 25px;

	li {

		button {
			color: #5E5C7F;
			cursor: pointer;
			font-family: $fonthead;
			font-size: 16px;
			font-weight: 700;
			position: relative;
			@include transition(.3s);
			height: auto;
			padding: 0;
			border: 0;
			outline: 0;
			background: transparent;

			&:hover,
			&.current {
				color: $colorpink;
			}
		}
	}

	li:not(:last-child) {
		margin-right: 1.8rem;
	}
}

select.portfolio-filter-mobile {
	font-size: 16px;
	border-radius: 10px;
	outline: 0;
	margin-bottom: 30px;
	position: relative;
	width: 100%;
}

.pf-filter-wrapper {
	position: relative;

	&:after {
		content: '\f0d7';
		font-family: 'FontAwesome';
		position: absolute;
		top: 0;
		right: 20px;
		color: #000;
	}
}

/*=================================================================*/
/*                      FACTS                              
/*=================================================================*/
.fact-item {
	.details {
		margin-left: 60px;
	}

	.icon {
		font-size: 36px;
		color: #DEDEEA;
		float: left;
	}

	.number {
		font-size: 30px;

		em {
			font-style: normal;
		}
	}

	p {
		font-size: 16px;
	}
}



/*=================================================================*/
/*                      CLIENTS                              
/*=================================================================*/
.client-item {
	min-height: 50px;
	position: relative;
	text-align: center;
	margin-top: 60px;

	.inner {
		text-align: center;
		position: absolute;
		left: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 100%;
	}

	img {
		display: inline-block;
	}
}

/*=================================================================*/
/*                      CONTACT                              
/*=================================================================*/
.contact-info {
	min-height: 200px;

	h3 {
		font-size: 23px;
		margin: 0 0 10px;
	}
}

/*=================================================================*/
/*                      HELPER                              
/*=================================================================*/
.help-block.with-errors {
	color: red;
	font-size: 12px;
	padding-left: 30px;

	ul {
		margin-bottom: 0;
	}
}

.spacer {
	clear: both;


	&[data-height='60'] {
		height: 60px;
	}

	&[data-height='70'] {
		height: 70px;
	}

	&[data-height='30'] {
		height: 30px;
	}

	&[data-height='96'] {
		height: 96px;
	}

	&[data-height='20'] {
		height: 20px;
	}
}

.text-link {
	font-family: $fonthead;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
}

#infscr-loading {
	position: absolute;
	left: 50%;
	margin-left: -15px;
	bottom: 10px;
}

#infscr-loading img {
	display: none;
}

.fa-spinner {
	margin-right: 10px;
	display: none;
}

.scroll-down {
	position: absolute;
	bottom: 40px;
	left: 0;
	width: 100%;
	z-index: 1;

	button {
		background: transparent;
		outline: 0;
		border: 0;
		cursor: pointer;
	}

	
}

@-webkit-keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

@-moz-keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

@keyframes ani-mouse {
	0% {
		top: 29%;
	}

	15% {
		top: 50%;
	}

	50% {
		top: 50%;
	}

	100% {
		top: 29%;
	}
}

.mouse-wrapper {
	color: #FFF;
	font-size: 14px;
	display: block;
	max-width: 100px;
	margin: auto;
	text-align: center;
	cursor: pointer;

	span {
		color: #ffffff;
	}

	&:hover {
		color: #FFF;
	}
}

.mouse {
	border: solid 2px #FFF;
	border-radius: 16px;
	display: block;
	margin: auto;
	margin-top: 10px;
	height: 26px;
	position: relative;
	width: 20px;

	.wheel {
		background: #FFF;
		border-radius: 100%;
		display: block;
		position: absolute;
		top: 8px;
		left: 50%;
		transform: translateX(-50%);
		height: 4px;
		width: 4px;
		-webkit-animation: ani-mouse 2s linear infinite;
		-moz-animation: ani-mouse 2s linear infinite;
		animation: ani-mouse 2s linear infinite;
	}
}

.circle {
	border-radius: 100%;
}

footer.footer {
	background: $colordark;
	padding: 40px 0;
	text-align: center;

	.copyright {
		color: #9C9AB3;
		font-size: 14px;
	}

	
}