/*=================================================================*/
/*                      DARK MODE                              
/*=================================================================*/
body.dark {
    color: #FFF;
    background-color: $colordark;

    .section-title {
        color: #FFF;

        &:before {
            
        }
    }

    .bg-dark {
        background: #3a393d !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #FFF;
    }

    blockquote {
        background: #5f5ca6;
    }

    .triangle-top:before {
        border-bottom-color: #302f4e;
    }

    .timeline.exp .timeline-container::after,
    .timeline.edu .timeline-container::after {
        background: #302f4e;
    }

    .portfolio-filter li button {
        color: #FFF;
    }

    a:hover {
        color: #FFF;
    }

    .portfolio-filter li button.current {
        color: $colorpink;
    }

    .blog-item .details h4.title a {
        color: #FFF;

        &:hover {
            color: $colorpink;
        }
    }

    .white-popup {
        background: #302f4e;
    }

    footer.footer {
        border-top: solid 1px rgba(255, 255, 255, 0.1);
    }

    .content-blog,
    .blog-page-section {
        background: #353353;
    }

    .bg-white {
        background-color: #303033 !important;
    }

   
}