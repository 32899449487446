$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #3a393d;
$coloraqua: aqua;
$colorpink: #FF4C60;
$colorgray: #7b7a80;
$colordefault: #3a393d;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}